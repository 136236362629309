import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getAllBanners, deleteBanner } from "../../redux/actions/banner";
import { toast } from "react-toastify";
import styles from "../../styles/styles";


const AllBanner = () => {
    const dispatch = useDispatch();
    const { banners, error } = useSelector((state) => state.banners);
    const [open, setOpen] = useState(false);
    const [bannerId, setBannerId] = useState("");

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
        dispatch(getAllBanners());
    }, [dispatch, error]);

    const handleDelete = async (id) => {
        await dispatch(deleteBanner(id));
        dispatch(getAllBanners());
        // window.location.reload()
    };

    const columns = [
        // { field: "id", headerName: "Banner ID"},
        {
            field: "name",
            headerName: "Name",
        },
        {
            field: "title",
            headerName: "Title",
        },
        {
            field: "subtitle",
            headerName: "Subtitle",
        },
        {
            field: "tags",
            headerName: "Tags",
        },
        {
            field: "createdAt",
            headerName: "Created Date",
        },
        {
            field: "actions",
            headerName: "Actions",
        },
    ];

    const row = [];
    banners &&
        banners.forEach((item) => {
            row.push({
                id: item._id,
                name: item.name,
                title: item.title,
                subtitle: item.subtitle,
                tags: item.tags,
                createdAt: item.createdAt.slice(0, 10),
            });
        });
    const rows = banners &&
        banners?.map((item) => ({
            id: item._id,
            name: item.name,
            title: item.title,
            subtitle: item.subtitle,
            tags: item.tags,
            createdAt: item.createdAt.slice(0, 10),
        }));

    return (
        <div className="w-full p-2 md:p-6 bg-deepblue md:h-[88vh] overflow-hidden">
            <div className="bg-white relative w-full p-4 h-[85vh] max-h-[85vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
                <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
                    <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
                        <span className="hidden sm:block"> ADMIN </span>  <span className="leading-tight"> [LIST OF WEBSITE HOME DISPLAY BANNER] </span>
                    </h3>
                </div>
                <div className="w-full pt-1 bg-white mt-2 max-h-[70vh] overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr className="text-left bg-gray-200">
                                {columns?.map((column) => (
                                    <th
                                        key={column.field}
                                        className="px-2 py-2 text-xs font-semibold text-gray-700 sm:px-4 sm:text-sm md:text-base"
                                    >
                                        {column.headerName}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((row) => (
                                <tr key={row.id} className="border-b">
                                    {columns.map((column) => (
                                        <td
                                            key={column.field}
                                            className="px-2 py-2 text-xs text-gray-600 sm:px-4 sm:text-sm md:text-base"
                                        >
                                            {column.field === "actions" ? (
                                                <button onClick={() => setBannerId(row.id) || setOpen(true)} className="px-4 py-2 font-bold">
                                                    <AiOutlineDelete size={20} />
                                                </button>
                                            ) : (
                                                row[column.field]
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {open && (
                    <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000090] flex items-center justify-center h-screen">
                        <div className="w-[95%] 800px:w-[32%] min-h-[17vh] bg-darkblue rounded-lg shadow overflow-hidden">
                            <div className="w-full px-4 py-2 sm:flex sm:items-start">
                                <div className="py-4 sm:flex sm:items-start bg-darkblue">
                                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                        {/* Icon */}
                                        <AiOutlineDelete size={20} className="font-bold text-red-900" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg font-medium leading-6 text-white">Delete Confirmation</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-white">Are you sure you want to delete this banner?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="items-center justify-center gap-8 px-4 py-3 bg-white sm:px-6 sm:flex">
                                <div
                                    className={`${styles.button8}`}
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </div>
                                <div
                                    className={`${styles.button7}`}
                                    onClick={() => setOpen(false) || handleDelete(bannerId)}
                                >
                                    Confirm
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllBanner;
