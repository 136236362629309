import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "../../styles/styles";
import { createTestimony } from "../../redux/actions/testimony";


const CreateTestimony = () => {

    const { success, error } = useSelector((state) => state.testimonys);
    //console.log(testimony)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [images, setImages] = useState([]);
    const [name, setName] = useState("");


    useEffect(() => {
        if (error) {
            toast.error(error);
        }
        if (success) {
            toast.success("Testimony created successfully!");
            navigate("/admin-view-testimonys");
        }
    }, [dispatch, error, success, navigate]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        setImages([]);

        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImages((old) => [...old, reader.result]);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newForm = new FormData();

        images.forEach((image) => {
            newForm.set("images", image);
        });
        newForm.append("name", name);
     
        dispatch(
            createTestimony(
                {
                    name,
                    images,
                }
            )
        );
    };


    return (
        <div className="w-full p-2 md:p-6 bg-deepblue md:h-[88vh] overflow-hidden">
            <div className="bg-white relative w-full p-4 h-[85vh] max-h-[85vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
                <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
                    <h3 className="flex gap-2 items-start justify-start text-lg sm:text-xl lg:text-2xl font-semibold font-Roboto text-white">
                        <span className="hidden sm:block"> ADMIN </span>  <span className="leading-tight"> [ADD WEBSITE HOME DISPLAY TESTIMONY] </span>
                    </h3>
                </div>
                {/* create banner form */}
                <form onSubmit={handleSubmit}>
                    <div className="w-full px-2 py-4 md:py-4 md:px-4 pb-0">
                        <div className="md:flex w-full gap-4 mt-2 mad:mt-6">
                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Name <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={name}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your banner name..."
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Upload Images <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="file"
                                    name=""
                                    id="upload"
                                    className="hidden"
                                    multiple
                                    onChange={handleImageChange}
                                />
                                <div className="w-full flex items-center flex-wrap">
                                    <label htmlFor="upload">
                                        <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
                                    </label>
                                    {images &&
                                        images.map((i) => (
                                            <img
                                                src={i}
                                                key={i}
                                                alt=""
                                                className="h-[120px] w-[120px] object-cover m-2"
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>

        

                        <div className="flex w-full mt-8">
                            <div className="w-full pb-0">
                                <input
                                    type="submit"
                                    value="Create Testimony"
                                    className={`${styles.input3} text-white mt-5 cursor-pointer bg-darkblue py-2 font-semibold border-0 hover:bg-black`}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateTestimony
