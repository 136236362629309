import React from "react";
import { useSelector } from "react-redux";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";

const FeaturedProducts = () => {
    const { allProducts, isLoading } = useSelector((state) => state.products);


    return (
        <>
            {
                isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <Header activeHeading={3} />
                        <br />
                        <br />
                        <div className={`${styles.section}`}>
                            <div className={`${styles.heading3}`}>
                                    <h4 className="px-4 py-2 bg-deepblue font-medium text-lg">All Courses</h4>
                            </div>
                            <div className="grid grid-cols-2 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">
                                {
                                    allProducts?.map((i, index) => {
                                        if (i.tags === "featured") {
                                            return (
                                                <ProductCard data={i} key={index} />
                                            );
                                        }
                                        return null;  // Return null if the condition is not met
                                    })
                                }
                            </div>
                        </div>
                        <Footer />
                    </div>
                )
            }
        </>
    )
}


export default FeaturedProducts

