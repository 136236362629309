
// export const server = "https://wumbisconceptbackend-3.onrender.com/api/v2";
// export const backend_url = "https://wumbisconceptbackend-3.onrender.com/";

export const server = "https://wumbisdeployment-3wfb.vercel.app/api/v2";
export const backend_url = "https://wumbisdeployment-3wfb.vercel.app/"


// export const server = "http://localhost:8000/api/v2";
// export const backend_url = "http://localhost:8000/"


