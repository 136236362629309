import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/styles'; // Ensure this path is correct for your styles

const DropDown2 = ({ categoriesData }) => {
    const navigate = useNavigate();
    const [isList, setIsList] = useState(false);
    const [openCategories, setOpenCategories] = useState({});

    const handleCategoryClick = (categoryId) => {
        setOpenCategories((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId],
        }));
    };


    const handleBrandClick = (id, brandName) => {
        navigate(`/brand/${id}`, { state: { brandName } });
    };


    return (
        <div className="relative">
            <div
                onClick={() => setIsList(!isList)}
                className="flex items-center justify-between w-full px-2 md:px-2 lg:px-1 py-2 lg:-4 text-sm font-medium leading-none text-white bg-deepblue rounded-t border-1 gap-2 border-white shadow cursor-pointer"
            >
                <p className="text-base font-bold">Courses List</p>
                <div>
                    {isList ? (
                        <svg width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z" fill="#FFF" />
                        </svg>
                    ) : (
                        <svg width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z" fill="#FFF" />
                        </svg>
                    )}
                </div>
            </div>
            {isList && (
                <div className="absolute z-50 w-full sm:w-64 px-4 py-2 mt-0 bg-deepblue rounded-b shadow md:mt-[4px]  lg:mt-[7px]">
                    {categoriesData &&
                        categoriesData.map((category) => (
                            <div key={category._id}>
                                <div
                                    className={`${styles.noramlFlex} font-semibold cursor-pointer`}
                                    onClick={() => handleCategoryClick(category._id)}
                                >
                                    <svg
                                        className={`rotate transition-transform duration-300 ${openCategories[category._id] ? 'rotate-90' : 'rotate-0'
                                            }`}
                                        height={12}
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.5 3L7.5 6L4.5 9"
                                            stroke="#fff"
                                            strokeWidth="1.25"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <h3 className="mx-3 my-1 select-none text-sm text-white font-semibold ">{category.name}</h3>
                                </div>
                                {openCategories[category._id] && (
                                    <ul className="ml-5 border-t-[1.5px] border-white">
                                        {category.brands.map((brand) => (
                                            <li
                                                key={brand._id}
                                                className="ml-4 text-sm font-[600] mt-0 lg:mt-1 cursor-pointer text-white"
                                                onClick={() => handleBrandClick(brand._id, brand.name)}
                                            >
                                                {brand.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default DropDown2;
