import React from "react";
import { Link } from "react-router-dom";


const testimonials = [
    {
        body: 'Good morning sis, trust you are doing great?. I just thought to tell you that i go a job with Ontario health. I really appreciate all your help and encouragement. God bless you immensely',
        author: {
            name: 'Leslie Alexander',
            handle: 'lesliealexander',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        body: "Joining your class wasn't planned for initially but the opportunity came at a time when i was at a crossroad and almost giving up on my BA journey. I had gone for a training before, however there was no personal touch to the first training i had which was more like a cosmetic & superficial approach that never addressed the root of my problem",
        author: {
            name: 'Leslie Alexander',
            handle: 'lesliealexander',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        body: "With your class, there is a difference. Your delivery style has a personal touch which makes it easy to picture, connect the dots & relate with BA roles. Your approach made it simpler to conceptualize BA. Now i understand my roles as a BA better in practical terms unlike before",
        author: {
            name: 'Leslie Alexander',
            handle: 'lesliealexander',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        body: "Before the training, my understanding of business analysis was fairly basic. i had a general grasp of its importance in identifying business needs and finding effective solutions but i lacked in-depth knowledge of specific method and tools used on this field ",
        author: {
            name: 'Leslie Alexander',
            handle: 'lesliealexander',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },

    // More testimonials...
]


const Testimonies = () => {
    return (
        <>
            <div className="py-10 mx-auto xl:max-w-[1320px] mt-8 md:mt-8 lg:mt-10 md:py-8  px-8 md:px-24 lg:py-16 lg:px-16 xl:px-32 bg-deepblue rounded-xl">
                {/* <div className="grid w-full px-8 py-8 pb-16 mt-0 grid-rows bg-ping-600 md:mt-12 md:mb-12 md:pb-16 md:py-12 md:px-14 gap-y-2 bg-deepblue rounded-xl"> */}
                <div className="max-w-xl mx-auto text-center">
                    <p className="mt-2 text-xl font-bold leading-7 text-center text-white sm:text-4xl md:text-4xl">
                        What our trainies are saying about our courses
                    </p>
                </div>
                <div className="flow-root mx-auto mt-8 md:max-w-3xl xl:max-w-full md:mt-12 lg:mx-0 lg:max-w-none">
                    <div className="-mt-8 md:-mx-4 sm:columns-2 sm:text-[0]">
                        {testimonials.map((testimonial) => (
                            <div key={testimonial.author.handle} className="pt-8 sm:inline-block sm:w-full md:px-4">
                                <figure className="p-8 text-sm leading-6 rounded-2xl bg-gray-50 ">
                                    <blockquote className="text-gray-900">
                                        <p className="text-justify line-clamp-3">{`“${testimonial.body}”`}</p>
                                    </blockquote>
                                    <figcaption className="flex items-center mt-0 gap-x-4">
                                        {/* <img className="w-10 h-10 rounded-full bg-gray-50" src={testimonial.author.imageUrl} alt="" />
                                        <div>
                                            <div className="font-semibold text-gray-900">{testimonial.author.name}</div>
                                            <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                                        </div> */}
                                    </figcaption>
                                </figure>
                            </div>
                        ))}

                    </div>
                    <div className="flex justify-center md:mt-8 lg:mt-12 mt-4">
                        <Link className="px-4 py-3 font-bold text-black bg-white rounded-lg " type="button" to="/testimonial"> Load More </Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Testimonies
