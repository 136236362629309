import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import TestimonyCard from './TestimonyCard';
import { getAllTestimonys } from '../../../redux/actions/testimony';

const Testimony = () => {
    const dispatch = useDispatch();
    const { testimonys, error, isLoading } = useSelector((state) => state.testimonys);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    useEffect(() => {
        dispatch(getAllTestimonys());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    // Calculate total pages
    const totalPages = Math.ceil(testimonys?.length / itemsPerPage);

    // Get current testimonies
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTestimonys = testimonys?.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            <div>
                {!isLoading && testimonys && testimonys.length > 0 && (
                    <div className="flex flex-wrap w-full gap-5">
                        {currentTestimonys.map((tes) => (
                            <TestimonyCard data={tes} key={tes._id} />
                        ))}
                    </div>
                )}

                {/* Pagination Controls */}
                <div className="flex justify-center items-center mt-4">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="px-3 py-2 mx-1 bg-deepblue text-white rounded disabled:opacity-50"
                    >
                        Previous
                    </button>
                    <span className="px-3 py-2 mx-1 bg-black rounded text-white">
                        {currentPage} / {totalPages}
                    </span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="px-3 py-2 mx-1 bg-darkblue text-white rounded disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Testimony;
