import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    isLoading: true,
};

export const testimonyReducer = createReducer(initialState, (builder) => {
    builder
        .addCase('testimonyCreateRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('testimonyCreateSuccess', (state, action) => {
            state.isLoading = false;
            state.testimony = action.payload;
            state.success = true;
        })
        .addCase('testimonyCreateFail', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            state.success = false;
        })

        // get all Testimonys
        .addCase('getAllTestimonyRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('getAllTestimonySuccess', (state, action) => {
            state.isLoading = false;
            state.testimonys = action.payload;
        })
        .addCase('getAllTestimonyFailed', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })

        // delete Testimony
        .addCase('deleteTestimonyRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('deleteTestimonySuccess', (state, action) => {
            state.isLoading = false;
            state.message = action.payload;
        })
        .addCase('deleteTestimonyFailed', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
});
