import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
// import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from "@stripe/react-stripe-js";
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

//import Paying from "./Paying";
import formatNumber from "../../utils/formatNumber";


const Payment = () => {
  const [orderData, setOrderData] = useState([]);
  const [stripeApiKey, setStripeApiKey] = useState("");
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();


  useEffect(() => {
    const fetchStripeApiKey = async () => {
      try {
        const { data } = await axios.get(`${server}/payment/stripeapikey`);
        setStripeApiKey(data.stripeApikey);
      } catch (error) {
        console.error("Error fetching Stripe API key:", error);
      }
    };
    fetchStripeApiKey();
  }, []);

  
  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "USD",
              value: orderData?.totalPrice,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      let paymentInfo = payer;

      if (paymentInfo !== undefined) {
        paypalPaymentHandler(paymentInfo);
      }

    });
  };

  const paypalPaymentHandler = async (paymentInfo) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const order = {
      cart: orderData?.cart,
      shippingAddress: orderData?.shippingAddress,
      user: user && user,
      totalPrice: orderData?.totalPrice,
      paymentInfo: {
        id: paymentInfo.payer_id,
        status: "succeeded",
        type: "Paypal",
      },
    };

    await axios
      .post(`${server}/order/create-order`, order, config)
      .then((res) => {
        setOpen(false);
        navigate("/order/success");
        toast.success("Order successful!");
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("latestOrder", JSON.stringify([]));
        window.location.reload();
      });
  };


  const paymentData = {
    amount: Math.round(orderData?.totalPrice * 100),
  };
 
  // Stripe payment hander section goes here

  const paymentHandler = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

       const paymentData = {
        amount: Math.round(orderData?.totalPrice * 100),
      };
      
      const { data } = await axios.post(
        `${server}/payment/process`,
        paymentData,
        config
      );

      const client_secret = data.client_secret;

      if (!stripe || !elements) return;
      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });

      if (result.error) {
        toast.error(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          order.paymnentInfo = {
            id: result.paymentIntent.id,
            status: result.paymentIntent.status,
            type: "Credit Card",
          };

          await axios
            .post(`${server}/order/create-order`, order, config)
            .then((res) => {
              setOpen(false);
              navigate("/order/success");
              toast.success("Your Registration Is Successful!");
              localStorage.setItem("cartItems", JSON.stringify([]));
              localStorage.setItem("latestOrder", JSON.stringify([]));
              window.location.reload();
            });
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // Cash on delevery section goes here
  const cashOnDeliveryHandler = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const order = {
      cart: orderData?.cart,
      shippingAddress: orderData?.shippingAddress,
      user: user && user,
      totalPrice: orderData?.totalPrice,
      paymentInfo: {
        type: "Cash On Delivery",
      },
    };

    await axios
      .post(`${server}/order/create-order`, order, config)
      .then((res) => {
        setOpen(false);
        navigate("/order/success");
        toast.success("Order successful!");
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("latestOrder", JSON.stringify([]));
        window.location.reload();
      });
  };


  return (
    <div className="flex flex-col items-center w-full py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <PaymentInfo
            user={user}
            open={open}
            setOpen={setOpen}
            onApprove={onApprove}
            createOrder={createOrder}
            paypalPaymentHandler={paypalPaymentHandler}
           paymentHandler={paymentHandler}
            cashOnDeliveryHandler={cashOnDeliveryHandler}
          />
        </div>
        <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData orderData={orderData} />
        </div>
      </div>
    </div>
  );
};

const PaymentInfo = ({
  user,
  open,
  setOpen,
  onApprove,
  createOrder,
  paymentHandler,
  paypalPaymentHandler,
  cashOnDeliveryHandler,
}) => {
  const [select, setSelect] = useState(1);

 
  return (
    <div className="w-full 800px:w-[95%] bg-darkblue rounded-md py-4 px-5 sm:mt-5 gap-y-10">

      
      <div className="mt-2">
        <div className="flex w-full">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#fff] relative flex items-center justify-center"
            onClick={() => setSelect(4)}
          >
            {select === 4 ? (
              <div className="w-[13px] h-[13px] bg-[#fff] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#fff]">
            Make Payment With Card
          </h4>
        </div>

        {select === 4 ? (
          <div className="flex w-full sm:pl-8">
            <form className="w-full" onSubmit={paymentHandler}>
              <div className="flex w-full pb-3 lg:mt-4">
                <div className="w-[50%]">
                  <label className="block pb-2 text-white">Name On Card</label>
                  <input
                    required
                    placeholder={user && user.name}
                    className={`${styles.input} !w-[95%] text-[#444]`}
                    value={user && user.name}
                  />
                
                </div>
                <div className="w-[50%]">
                  <label className="block pb-2 text-white">Exp Date</label>
                  <CardExpiryElement
                    className={`${styles.input}`}
                    options={{
                      style: {
                        base: {
                          fontSize: "19px",
                          lineHeight: 1.5,
                          color: "#444",
                        },
                        empty: {
                          color: "#3a120a",
                          backgroundColor: "#fff",
                          "::placeholder": {
                            color: "#444",
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div className="flex w-full pb-3">
                <div className="w-[50%]">
                  <label className="block pb-2 text-white">Card Number</label>
                  <CardNumberElement
                    className={`${styles.input} !h-[35px] !w-[95%]`}
                    options={{
                      style: {
                        base: {
                          fontSize: "19px",
                          lineHeight: 1.5,
                          color: "#444",
                        },
                        empty: {
                          color: "#3a120a",
                          backgroundColor: "#fff",
                          "::placeholder": {
                            color: "#444",
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div className="w-[50%]">
                  <label className="block pb-2 text-white">CVV</label>
                  <CardCvcElement
                    className={`${styles.input} !h-[35px]`}
                    options={{
                      style: {
                        base: {
                          fontSize: "19px",
                          lineHeight: 1.5,
                          color: "#444",
                        },
                        empty: {
                          color: "#3a120a",
                          backgroundColor: "#fff",
                          "::placeholder": {
                            color: "#444",
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <input
                type="submit"
                value="Submit"
                className={`${styles.button} !bg-green-700 text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              />
            </form>
          </div>
        ) : null}
      </div>
      {/* Pay with Stripe ends here */}


      {/* PayPal payment */}
      {/* <div>
        <div className="flex w-full">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] relative flex items-center justify-center"
            onClick={() => setSelect(1)}
          >
            {select === 1 ? (
              <div className="w-[13px] h-[13px] bg-[#fff] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#fff]">
            Pay with PayPal
          </h4>
        </div>

        {select === 1 ? (
          <div className="flex w-full sm:pl-8">
            <div
              className={`${styles.button2} !w-[170px] border-2 border-white !md:w-[170px] px-2 text-white font-semibold `}
              onClick={() => setOpen(true)}
            >
              Pay with Paypal
            </div>
            {open && (
              <div className="w-full fixed top-0 left-0 bg-[#00000039] h-screen flex items-center justify-center z-[99999]">
                <div className="w-full 800px:w-[30%] 800px:h-[95vh] bg-white rounded-[5px] shadow flex flex-col justify-center p-8 relative">
                  <div className="flex justify-end w-full p-3 sm:mt-14">
                    <RxCross1
                      size={30}
                      className="absolute text-white bg-red-700 cursor-pointer top-3 right-3"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "Aczac4Ry9_QA1t4c7TKH9UusH3RTe6onyICPoCToHG10kjlNdI-qwobbW9JAHzaRQwFMn2-k660853jn",
                    }}
                    style={{ overflow: "scroll" }}
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      onApprove={onApprove}
                      createOrder={createOrder}
                    />
                  </PayPalScriptProvider>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div> */}
      {/* PayPal payment ends here */}

      {/* Pay with Paystack */}
      {/* <div className="mt-2">
        <div className="flex w-full">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#fff] relative flex items-center justify-center"
            onClick={() => setSelect(2)}
          >
            {select === 2 ? (
              <div className="w-[13px] h-[13px] bg-[#fff] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#fff]">
            Make Payment With Card
          </h4>
        </div>

      
        {select === 2 ? (
          <div className="flex w-full sm:pl-8">
            <Paying />
          </div>
        ) : null}
      </div> */}
      {/* Pay with Paystack ends here */}

     
    </div>
  );
};

const CartData = ({ orderData }) => {

  return (
    <>
      <div className="w-full px-5 py-4 rounded-md bg-darkblue sm:mt-5">
        <div className="flex justify-between border-b-2 border-lightenblue ">
          <h3 className="text-[16px] font-[600] text-white bg-deepble px-2 py-1">Application Fee:</h3>
          <h5 className="text-[18px] font-[600] px-1 py-1 text-white">{formatNumber(orderData?.subTotalPrice)}</h5>
        </div>
        <br />

        <br />
        <div className="flex justify-between border-b-2 border-lightenblue">
          <h3 className="text-[20px] font-[600] text-white bg-deepble px-2 py-1">Total Fee:</h3>
          <h5 className="text-[18px] font-[600]  px-1 py-1 text-white">${orderData?.totalPrice}</h5>
        </div>
      </div>
    </>
  );
};

export default Payment;
