import React from 'react';

const Container = ({ children }) => {
    return (
        <div>
            <div className='max-w-[1920px] mx-auto xl:py-10 xl:px-20 lg:px-8 md:px-8 px-6'>
                {children}
            </div>
        </div>
    );
}

export default Container;