import React from 'react'

const TestimonyCard = ({ data }) => {
    
  return (

      <>
          <div className="w-full sm:w-[450px] h-[325px] md:h-[530px]  flex flex-col sm:flex-none  sm:max-h-[900px] rounded-lg shadow-sm p-3 relative cursor-pointer bg-deepblue">
              {/* <div className="flex justify-end"></div> */}
              <div className="">
                  {/* <img
                      src={`${data.images && data.images[0]?.url}`}                 
                      alt=""
                      className="w-full h-[200px] sm:h-[200px] object-fill"
                  /> */}

                  <img
                      src={`${data.images && data.images[0]?.url }`}
                      alt=""
                      className="w-full h-[300px] md:h-[500px] object-fill"
                  />
              </div>
          </div >
      </>
  )
}

export default TestimonyCard
