import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const ShippingPage = () => {
  return (
      <div>
          <Header />
          <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-12 md:py-12 md:px-6 py-9 px-4">
              <div className="w-full items-center justify-start flex border-l-2 border-b-2 border-deepblue overflow-hidden rounded-l-lg">
                  <h3 className="text-[22px] font-Roboto text-white bg-deepblue py-2 px-4">Private Polices </h3>
              </div>
              {/* <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12 mt-2 sm:mt-8">
                  <div className="w-full lg:w-12/12">
                      <h2 className="w-full font-bold lg:text-xl text-md lg:leading-10 leading-9">Swift and Secure Delivery Across Nigeria</h2>
                      <p className=" text-base leading-6 text-gray-900 mt-2 text-justify ">At Naijawizard we are committed to providing you with a seamless and reliable shipping experience. Our goal is to ensure that your products reach you swiftly and safely, wherever you are in Nigeria.</p>
                  </div>
              </div> */}
              {/* Shipping Process Section */}
              {/* <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12 mt-2 sm:mt-8">
                  <div className="w-full lg:w-12/12">
                      <h2 className="w-full font-bold lg:text-xl text-md lg:leading-10 leading-9">Shipping Process:</h2>
                      <ul className="divide-y divide-gray-200">
             
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">1.</span>
                              <span className="text-justify"><strong>Order Processing:</strong> Once you place an order with us, our team immediately begins processing it. Orders received before [time] are typically processed on the same day.</span>
                          </li>
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">2.</span>
                              <span className="text-justify"><strong>Shipping Partners:</strong> We collaborate with trusted shipping partners to deliver your products efficiently.</span>
                          </li>
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">3.</span>
                              <span className="text-justify"><strong>Tracking Information:</strong> As soon as your order is dispatched, you will receive a tracking number via email or SMS, allowing you to monitor the status of your delivery in real-time.</span>
                          </li> 
                      </ul>

                  </div>
              </div> */}
              {/* Shipping Process ends here */}


              {/* Delivery Time Frame Section goes here */}
              {/* <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12 mt-2 sm:mt-8">
                  <div className="w-full lg:w-12/12">
                      <h2 className="w-full font-bold lg:text-xl text-md lg:leading-10 leading-9">Delivery Timeframes:</h2>
                      <ul className="divide-y divide-gray-200">
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">1.</span>
                              <span className="text-justify"><strong>Within Major Cities:</strong> Deliveries within major cities are usually completed within [X] business days from the date of dispatch.</span>
                          </li>
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">2.</span>
                              <span className="text-justify"><strong>Rural Areas:</strong> Delivery to rural areas may take slightly longer, typically within [X] to [X] business days.</span>
                          </li>
                      </ul>
                      <p>Please note that unforeseen circumstances such as adverse weather conditions or public holidays may affect delivery times. However, rest assured, we strive to keep any delays to a minimum.</p>
                  </div>
              </div> */}
              {/* Delivery Time Frame Section goes here */}
             
              {/* Shipping Fee Section Goes here */}
              {/* <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12 mt-2 sm:mt-8">
                  <div className="w-full lg:w-12/12">
                      <h2 className="w-full font-bold lg:text-xl text-md lg:leading-10 leading-9">Shipping Fees:</h2>
                      <ul className="divide-y divide-gray-200">
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">1.</span>
                              <span className="text-justify"><strong>Standard Shipping:</strong> We offer standard shipping at a flat rate of [X] Naira for orders below [X] Naira. Orders above [X] Naira qualify for free standard shipping.</span>
                          </li>
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">2.</span>
                              <span className="text-justify"><strong>Express Shipping:</strong> If you require expedited delivery, we also offer express shipping options at an additional cost. The exact fees and delivery times will be calculated at checkout.</span>
                          </li>
                      </ul>
    
                  </div>
              </div> */}
              {/* Shipping Fee Section ends here */}

              {/* Shipping Restriction Section Goes Here */}
              {/* <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12 mt-2 sm:mt-8">
                  <div className="w-full lg:w-12/12">
                      <h2 className="w-full font-bold lg:text-xl text-md lg:leading-10 leading-9">Shipping Restrictions:</h2>
                      <ul className="divide-y divide-gray-200">
                          <li className=" flex px-[0.5rem] items-start">
                              <span classNAme="text-lg font-semibold mr-2">1.</span>
                              <span className="text-justify"><strong>International Shipping:</strong> At the moment, we only ship within Nigeria. We do not offer international shipping services.</span>
                          </li>
                          <li className=" flex px-[0.5rem] items-start">
                              <span className="text-lg font-semibold mr-2">2.</span>
                              <span className="text-justify"><strong>Restricted Items:</strong> Certain items may be subject to shipping restrictions due to their nature or legal regulations. Please refer to our product descriptions or contact our customer service team for more information.</span>
                          </li>
                      </ul>

                  </div>
              </div> */}
              {/* Shipping Restriction section ends here */}

              {/* Contact us section goes here */}
              {/* <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12 mt-2 sm:mt-8">
                  <div className="w-full lg:w-12/12">
                      <h2 className="w-full font-bold lg:text-xl text-md lg:leading-10 leading-9">Contact Us:</h2>
                      <p className="text-justify">
                          Should you have any questions or concerns regarding our shipping policy, feel free to reach out to our customer service
                          team at [Contact Information]. We are here to assist you every step of the way and ensure that your shopping experience with
                          us is exceptional.
                      </p>
                      <p className="mt-4 text-justify">
                          Thank you for choosing [Your Company Name] for your shipping needs. We look forward to serving you!
                      </p>
                  </div>
              </div> */}
              {/* Contact us section ends here */}
          </div>
          <Footer />
    </div>
  )
}

export default ShippingPage
