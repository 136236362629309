// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: "/aboutus",
  },
  // {
  //   title: "Our Services",
  //   url: "/privacy",
  // },
  {
    title: "Consultation",
    url: "/consult",
  },
  {
    title: "Contact",
    url: "/contactus",
  },
  {
    title: "FAQ",
    url: "/faq",
  },
  // {
  //   title: "Events",
  //   url: "/events",
  // },
  // {
  //   url: "/special",
  // },
  // {
  //   url: "/popular",
  // },
  // {
  //   url: "/feature",
  // },
];

// branding data
export const brandingData = [
  {
    id: 1,
    title: "Affordable Shipping",
    Description: "From all orders over $100",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H5.63636V24.1818H35"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M34.9982 1H11.8164V18H34.9982V1Z"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M11.8164 7.18164H34.9982"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Daily Surprise Offers",
    Description: "Save up to 25% off",
    icon: (
      <svg
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M30.7 2L29.5 10.85L20.5 9.65"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    title: "Affortable Prices",
    Description: "Get Factory direct price",
    icon: (
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M16 28V22"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
        ></path>
        <path
          d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
        <path
          d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
          stroke="#133A1B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        ></path>
      </svg>
    ),
  },
];

export const categorytags = [
  {
    id: 1,
    section: "Home Section",
  },
  // {
  //   id: 2,
  //   section: 2,
  // },
  // {
  //   id: 3,
  //   section: 3,
  // },
  // {
  //   id: 4,
  //   section: 4,
  // },
  // {
  //   id: 5,
  //   section: 5,
  // },
  // {
  //   id: 6,
  //   section: 6,
  // },
  // {
  //   id: 7,
  //   section: 7,
  // },
  // {
  //   id: 8,
  //   section: 8,
  // },
  // {
  //   id: 9,
  //   section: 9,
  // },
  // {
  //   id: 10,
  //   section: 10,
  // },
  // {
  //   id: 11,
  //   section: 11,
  // },
  // {
  //   id: 12,
  //   section: 12,
  // },
  // {
  //   id: 13,
  //   section: 13,
  // },
]


export const zones = [
  { id: 1, title: "UTC" },
  { id: 2, title: "GMT" },
  { id: 3, title: "EST" },
  { id: 4, title: "CST" },
  { id: 5, title: "MST" },
  { id: 6, title: "PST" },
  { id: 7, title: "EDT" },
  { id: 8, title: "CDT" },
  { id: 9, title: "MDT" },
  { id: 10, title: "PDT" },
  { id: 11, title: "BST" },
  { id: 12, title: "CET" },
  { id: 13, title: "EET" },
  { id: 14, title: "ART" },
  { id: 15, title: "HST" },
  { id: 16, title: "AKST" },
  { id: 17, title: "AEST" },
  { id: 18, title: "ACST" },
  { id: 19, title: "AWST" },
  { id: 20, title: "NZST" },
  { id: 21, title: "IST" },
  { id: 22, title: "SGT" },
  { id: 23, title: "JST" },
  { id: 24, title: "KST" },
  { id: 25, title: "CST" },
  { id: 26, title: "WIB" },
  { id: 27, title: "WITA" },
  { id: 28, title: "WIT" }
]

export const weekdays = [
  {
    id: 1,
    title: "Monday",
  },
  {
    id: 2,
    title: "Tuesday",
  },
  {
    id: 3,
    title: "Wednesday",
  },
  {
    id: 4,
    title: "Thusday",
  },
  {
    id: 5,
    title: "Friday",
  },
  {
    id: 5,
    title: "Saturday",
  },
  {
    id: 7,
    title: "Sunday",
  },
  
]


export const tagsData = [
  {
    id: 1,
    title: "popular",
  },
  {
    id: 2,
    title: "featured",
  },
  {
    id: 3,
    title: "special",
  },
  {
    id: 4,
    title: "newarrivals",
  },
  {
    id: 5,
    title: "trendingnow",
  },
  {
    id: 5,
    title: "limitededition",
  },
  {
    id: 7,
    title: "toprated",
  },
  {
    id: 8,
    title: "backinstock",
  },
  {
    id: 9,
    title: "exclusive",
  },
  {
    id: 10,
    title: "hotdeals",
  },
  {
    id: 11,
    title: "holidayspecials",
  },
  {
    id: 12,
    title: "flashdeals",
  },
  {
    id: 13,
    title: "limitedtimeoffer",
  },
]

export const bannertagsData = [
  {
    id: 1,
    title: "text and image",
  },
  // {
  //   id: 2,
  //   title: "text alone",
  // },
  // {
  //   id: 3,
  //   title: "image alone",
  // },
]

export const eventtagsData = [
  {
    id: 1,
    title: "Bigevent",
  },
]

// categories data
export const categoriesData = [
  {
    id: 1,
    title: "Computers and Laptops",
    subTitle: "",
  },
  {
    id: 2,
    title: "Cosmetics and Body Care",
    subTitle: "",

  },
  {
    id: 3,
    title: "Accesories",
    subTitle: "",
  },
  {
    id: 4,
    title: "Flowers",
    subTitle: "",
  },
  {
    id: 5,
    title: "Apliances",
    subTitle: "",
  },
  {
    id: 6,
    title: "Electronics",
    subTitle: "",
  },
  {
    id: 7,
    title: "Supermarket",
    subTitle: "",
  },
  {
    id: 8,
    title: "Health and Beauty",
    subTitle: "",
  },
  {
    id: 9,
    title: "Home and Office",
    subTitle: "",
  },
  {
    id: 10,
    title: "Women's Fashion",
    subTitle: "",
  },
  {
    id: 11,
    title: "Men's Fashion",
    subTitle: "",
  },
  {
    id: 12,
    title: "Baby Products",
    subTitle: "",
  },
  {
    id: 13,
    title: "Gaming",
    subTitle: "",
  },
  {
    id: 14,
    title: "Sporting Goods",
    subTitle: "",
  },
  {
    id: 15,
    title: "Auto Mobile",
    subTitle: ""
  },
  {
    id: 16,
    title: "Bags",
    subTitle: "",
  },
  {
    id: 17,
    title: "Household Cleaning",
    subTitle: "",
  },
  {
    id: 19,
    title: "Garden & Outdoors",
    subTitle: "",
  },
  {
    id: 19,
    title: "Music and Gaming",
    subTitle: "",
  },
  {
    id: 20,
    title: "Grocery",
    subTitle: "",
  },
  {
    id: 21,
    title: "Shoes",
    subTitle: "",
  },
  {
    id: 22,
    title: "Gifts",
    subTitle: "",
  },
  {
    id: 23,
    title: "Pet Care",
    subTitle: "",
  },
  {
    id: 24,
    title: "Mobile and Tablets",
    subTitle: "",
  },
  {
    id: 25,
    title: "Others",
    subTitle: "",
  },
];


export const footerProductLinks = [
  {
    name: "About us",
    link: "/aboutus"
  },
  {
    name: "Store Locations",
    link: "/location"
  }
];

export const footercompanyLinks = [
  {
    name: "Game & Video",
  },
  {
    name: "Phone &Tablets",
  },
  {
    name: "Computers & Laptop",
  },
  {
    name: "Sport Watches",
  },
  {
    name: "Events",
  },
];

export const footerSupportLinks = [
  {
    name: "FAQ",
    link: "/faq",
  },
  {
    name: "Contact Us",
    link: "/contactus"
  },
  {
    name: "Shipping",
    link: "/shippingpage"
  },
  {
    name: "Live chat",
  },
];
