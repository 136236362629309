import axios from "axios";
import { server } from "../../server";

// create testimony
export const createTestimony = (testimonyData) => async (dispatch) => {
    try {
        dispatch({
            type: "testimonyCreateRequest",
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(`${server}/testimony/create-testimony`, testimonyData, config);

        dispatch({
            type: "testimonyCreateSuccess",
            payload: data.testimony,
        });
    } catch (error) {
        dispatch({
            type: "testimonyCreateFail",
            payload: error.response.data.message,
        });
    }
};


// get all testimony
export const getAllTestimonys = () => async (dispatch) => {
    try {
        dispatch({
            type: "getAllTestimonyRequest",
        });

        const { data } = await axios.get(`${server}/testimony/get-all-testimonys`);

        dispatch({
            type: "getAllTestimonySuccess",
            payload: data.testimonys,
        });
    } catch (error) {
        dispatch({
            type: "getAllTestimonyFailed",
            payload: error.response.data.message,
        });
    }
};

// delete testimony
export const deleteTestimony = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "deleteTestimonyRequest",
        });

        const { data } = await axios.delete(`${server}/testimony/delete-testimony/${id}`, {
            withCredentials: true,
        });

        dispatch({
            type: "deleteTestimonySuccess",
            payload: data.message,
        });
    } catch (error) {
        dispatch({
            type: "deleteTestimonyFailed",
            payload: error.response.data.message,
        });
    }
};
