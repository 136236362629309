import React from 'react'
import AdminHeader from '../components/Layout/AdminHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import AllTestimony from '../components/Admin/AllTestimony';

const AdminDashboardTestimony = () => {
  return (
      <div>
          <AdminHeader />
          <div className="w-full flex">
              <div className="flex items-start justify-between w-full">
                  <div className="w-[80px] 800px:w-[330px]">
                      <AdminSideBar active={14} />
                  </div>
                  <AllTestimony />
              </div>
          </div>
      </div>
  )
}

export default AdminDashboardTestimony
