import React from "react";
import Accordian, { AccordianItem } from './Accordian'

const Faq = () => {
    return (
        <>
            
            <div className="py-0 px-0 mb-10 lg:py-4 mt-2 md:mt-0 lg:mt-0 md:mb-28 lg:px-0">
                    <div className="max-w-full md:px-6 mx-auto lg:px-0 flex items-start justify-center">
                        <Accordian className="w-full">
                            <AccordianItem value="1" trigger="Is this course suitable for beginners with zero experience?">
                                Yes, our course is specifically designed for students with zero experience in business
                                analysis and project management. Our instructors will guide you through the fundamentals and
                                help you build a solid foundation in the field.
                            </AccordianItem>
                            <AccordianItem value="2" trigger="What is the mode of your classes?">
                                Our classes are conducted live online, allowing students to participate from anywhere with an
                                internet connection. Recorded sessions are also available for review at your convenience.
                            </AccordianItem>
                            <AccordianItem value="3" trigger="Will I receive a certificate upon completion of the course?">
                                Yes, upon successful completion of the course and all requirements, you will receive a
                                certificate of completion from Wumbis. Additionally, you will be well-prepared to pursue
                                industry-standard certifications such as CBAP and PMP.
                            </AccordianItem>
                            <AccordianItem value="4" trigger="Can I access course materials and recordings after the live sessions?">
                                Yes, all course materials, recordings, and resources will be available to you through our
                                online learning platform for future reference.
                            </AccordianItem>
                            <AccordianItem value="5" trigger="Do you offer job placement assistance?">
                                While we do not offer job placement assistance, we provide career guidance and support to
                                help you transition into the field with confidence. Our team will assist you in preparing your
                                resume, practicing interview skills, and connecting you with job opportunities in the industry
                            </AccordianItem>
                            <AccordianItem value="6" trigger="Do you offer refunds if I am not satisfied with the course?">
                                No, we do not offer refunds for our courses. We are committed to providing high-quality
                                education and support to our students, and our no-refund policy reflects our dedication to
                                maintaining the integrity of our programs.
                            </AccordianItem>
                        </Accordian>
                    </div>
            </div>
        </>
    )
}

export default Faq
