import React from 'react'
import Header from "../components/Layout/Header";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Banner from '../components/Route/Banner/Banner';
import Footer from "../components/Layout/Footer";
import HappyClient from '../components/HappyClient';
import Testimonies from '../components/Testimonies';
import OurTeam from '../components/OurTeam';
import ExistingProductHeading from './ExistingProductHeading';


const HomePage = () => {
  return (
    <div>
      <Header activeHeading={1} />
      {/* <Hero /> */}
      <Banner />
      <ExistingProductHeading />
      <FeaturedProduct />
      {/* <OurTeam /> */}
      <Testimonies />
      <HappyClient />
      {/* <Blog /> */}
      <Footer />
    </div>
  )
}

export default HomePage