import React, { useEffect } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { FaArrowAltCircleLeft } from "react-icons/fa";

const OrderDetailsAdmin = () => {

    const { orders } = useSelector((state) => state.order);
    const { seller } = useSelector((state) => state.seller);
    const dispatch = useDispatch();


    const { id } = useParams();

    useEffect(() => {
        dispatch(getAllOrdersOfShop(seller._id));
    }, [dispatch, seller._id]);

    const data = orders && orders?.find((item) => item._id === id);


    function formatTime(timeString) {
        // Split the time string into hours and minutes
        let [hours, minutes] = timeString.split(':').map(Number);

        // Determine AM or PM suffix
        let suffix = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12 || 12;

        // Return the formatted time
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${suffix}`;
    }

  
  return (
    <div className={`py-4 min-h-screen md:min-h-[75vh] px-4 ${styles.section} bg-white`}>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="#000" />
          <h1 className="pl-2 text-[25px] font-semibold">User Applied Course Details Informations</h1>
        </div>
        <Link to="/admin-orders">
          <div
            className={`${styles.button} flex gap-1 !bg-darkblue !rounded-[4px] text-[#fff] font-semibold !h-[32px] text-[13px]`}
          > <FaArrowAltCircleLeft />
            Applied courses list
          </div>
        </Link>
      </div>

       <div className="container px-2 py-4 mx-auto md:px-0">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
            <thead>
              <tr className="text-white bg-gray-800">
                <th className="px-4 py-2 text-left">Course ID</th>
                <th className="px-4 py-2 text-left">Registration Date</th>
                <th className="px-4 py-2 text-left">Applied Course Title</th>
                <th className="px-4 py-2 text-left">Duration</th>
                <th className="px-4 py-2 text-left">Lecture Period</th>
                <th className="px-4 py-2 text-left">App. Fee</th>
                <th className="px-4 py-2 text-left">Contact Information</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.cart?.map((course) => (
                  <tr key={course.id} className="border-t border-gray-300">

                    {/* <h4 className="text-[#fff] bg-darkblue py-2">
          <span className="font-semibold pl-4 pr-[52px] py-4">Payment Status: </span>
          <span className="px-4 py-4 font-medium bg-lightenblue">
            {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </span>
        </h4> */}

                    <td className="px-4 py-2">#{data?._id?.slice(0, 8)}</td>
                    <td className="px-4 py-2">{data?.createdAt?.slice(0, 10)}</td>
                    {data &&
                      data?.cart.map((item, index) => (
                        <td className="flex flex-col items-center justify-center gap-1 px-4 py-2">
                          <img
                            src={`${item.images1[0]?.url}`}
                            alt=""
                            className="w-full h-[70px]"
                          />
                          {item.name}
                          {/* {item.startDate} */}
                        </td>
                      ))}
                    <td className="px-4 py-2">
                      {data &&
                        data?.cart.map((item, index) => (
                          <td className="">
                            {item.duration}
                          </td>
                        ))}
                    </td>
                    <td className="px-1 py-2">
                      {data &&
                        data?.cart.map((item, index) => (
                          <td className="flex flex-col px-4 py-2">
                            <span className="flex items-center justify-start"> <p className="mr-1 font-semibold"> Start Date: </p> {new Date(item.startDate).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</span>
                            <p className="mr-1 font-semibold">
                              Lecture days and Time
                            </p>
                            <span>
                              {item.days} {" - "} {formatTime(item.startTime)}
                            </span>
                            <span> {item.enddays} {" - "} {formatTime(item.endTime)}
                            </span>
                          </td>
                        ))}
                    </td>
                    <td className="px-4 py-2">${data?.totalPrice}</td>
                    <td className="px-4 py-2">{data?.shippingAddress?.address1 +
                      " " +
                      data?.shippingAddress?.address2}. {" "}
                      {data?.shippingAddress?.country}, {" "} {data?.shippingAddress?.city}.</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsAdmin
