import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import Footer from '../../components/Layout/Footer'
import OrderDetailsAdmin from '../../components/Shop/OrderDetailsAdmin';

const ShopOrderDetailsAdmin = () => {
    return (
        <div>
            <DashboardHeader />
            <OrderDetailsAdmin />
            <Footer />
        </div>
    )
}

export default ShopOrderDetailsAdmin
