import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateProduct, getProductDetails } from "../../redux/actions/product";
import { toast } from "react-toastify";
import { getAllCategories } from "../../redux/actions/category";
import { getAllBrands } from "../../redux/actions/brand";
import { categorytags, weekdays, zones } from "../../static/data";
import ReactQuill from 'react-quill';


const EditProduct = () => {
    const { id } = useParams(); // Get product ID from URL params
    const { seller } = useSelector((state) => state.seller);
    const { success, error, products } = useSelector((state) => state.products);
    const { categories } = useSelector((state) => state.categories);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedBrand, setSelectedBrand] = useState("");
    const [images1, setImages1] = useState([]);
    // const [images2, setImages2] = useState([]);
    // const [images3, setImages3] = useState([]);
    const [name, setName] = useState("");
    const [modeoftutor, setModeoftutor] = useState("");
    const [aboutthecourse, setAboutthecourse] = useState("");
    const [aimsandobjectives, setAimsandobjectives] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [duration, setDuration] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [timezone, setTimezone] = useState("");
    const [description, setDescription] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [filteredBrands, setFilteredBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [originalPrice, setOriginalPrice] = useState();
    const [discountPrice, setDiscountPrice] = useState();
    const [tags, setTags] = useState("");
    const [days, setDays] = useState("");
    const [enddays, setEndDays] = useState("");
    const [loading, setLoading] = useState(true); // New loading state


    // useEffect(() => {
    //     if (error) {
    //         toast.error(error);
    //     }
    //     if (success) {
    //         toast.success("Product updated successfully!");
    //         navigate("/dashboard-products");
    //         window.location.reload();
    //     }
    // }, [dispatch, error, success, navigate]);

   

    useEffect(() => {
        setLoading(true); // Start loading
        dispatch(getAllCategories());
        dispatch(getAllBrands());
        // Fetch product details
        dispatch(getProductDetails(id)).finally(() => {
            setLoading(false); // Stop loading when the data is fetched
        });
    }, [dispatch, id]);

    // useEffect(() => {
    //     if (error) {
    //         toast.error(error);
    //     }
    //     if (success) {
    //         toast.success("Product updated successfully!");

    //         // Introduce a slight delay before navigation
    //         setTimeout(() => {
    //             navigate("/dashboard-products");
    //         }, 1000); // 1 second delay

    //         // Introduce a further delay before reloading the page
    //         setTimeout(() => {
    //             window.location.reload();
    //         }, 1500); // 1.5 second delay
    //     }
    // }, [error, success, navigate]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
        if (success) {
            toast.success("Product updated successfully!");

            // Navigate immediately after success
            navigate("/dashboard-products");

            // Reload the page immediately after navigation
            window.location.reload();
        }
    }, [error, success, navigate]);

    useEffect(() => {
        // Fetch product details when component mounts
        dispatch(getProductDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        // Populate form with fetched product data
        if (id && Array.isArray(products)) {
            const currentProduct = products.find((e) => e._id === id);
            if (currentProduct) {
                setName(currentProduct.name);
                setDescription(currentProduct.description);
                setModeoftutor(currentProduct.modeoftutor);
                setAboutthecourse(currentProduct.aboutthecourse);
                setAimsandobjectives(currentProduct.aimsandobjectives);
                setSelectedCategory(currentProduct.category);
                setSelectedBrand(currentProduct.brand);
                setOriginalPrice(currentProduct.originalPrice);
                setDiscountPrice(currentProduct.discountPrice);
                setTags(currentProduct.tags);
                setDays(currentProduct.days);
                setEndDays(currentProduct.enddays);

                // Ensure startDate is a Date object
                setStartDate(currentProduct.startDate ? new Date(currentProduct.startDate) : new Date());

                setDuration(currentProduct.duration);
                setStartTime(currentProduct.startTime);
                setEndTime(currentProduct.endTime);
                setTimezone(currentProduct.timezone);

                setImages1(currentProduct?.images1?.map((img) => img.url));
                // setImages2(currentProduct?.images2?.map((img) => img.url));
                // setImages3(currentProduct?.images3?.map((img) => img.url));
            }
        }
    }, [id, products]);

    useEffect(() => {
        // Filter brands based on the selected category
        const selectedCategoryObj = categories.find(cat => cat._id === selectedCategory);
        if (selectedCategoryObj) {
            setFilteredBrands(selectedCategoryObj.brands);
        } else {
            setFilteredBrands([]);
        }
    }, [selectedCategory, categories]);

    const handleImageChange1 = (e) => {
        const files = Array.from(e.target.files);
        setImages1([]);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImages1((old) => [...old, reader.result]);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    // const handleImageChange2 = (e) => {
    //     const files = Array.from(e.target.files);
    //     setImages2([]);
    //     files.forEach((file) => {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             if (reader.readyState === 2) {
    //                 setImages2((old) => [...old, reader.result]);
    //             }
    //         };
    //         reader.readAsDataURL(file);
    //     });
    // };

    // const handleImageChange3 = (e) => {
    //     const files = Array.from(e.target.files);
    //     setImages3([]);
    //     files.forEach((file) => {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             if (reader.readyState === 2) {
    //                 setImages3((old) => [...old, reader.result]);
    //             }
    //         };
    //         reader.readAsDataURL(file);
    //     });
    // };


    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setSelectedCategory(selectedCategoryId);
        const selectedCategory = categories.find(cat => cat._id === selectedCategoryId);
        if (selectedCategory) {
            setFilteredBrands(selectedCategory.brands);
        } else {
            setFilteredBrands([]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true); // Start loading on submit
        const updatedProduct = {
            name,
            description,
            aboutthecourse,
            aimsandobjectives,
            startDate: startDate?.toISOString(),
            duration,
            startTime,
            endTime,
            timezone,
            modeoftutor,
            category: selectedCategory,
            brand: selectedBrand,
            originalPrice,
            discountPrice,
            tags,
            days,
            enddays,
            shopId: seller._id,
            images1,
            // images2,
            // images3,
        };

        // dispatch(updateProduct(id, updatedProduct));
        dispatch(updateProduct(id, updatedProduct)).finally(() => {
            setLoading(false); // Stop loading after submission
        });
    };

    // if (loading) {
    //     return <div>Loading...</div>; // Show loading spinner or message
    // }


    return (
        <div className="w-full p-2 md:p-6 bg-lightenblue md:h-[88vh] overflow-hidden">
            <div className="bg-white relative w-full p-4 md:h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
                <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-4 py-2 rounded-lg bg-darkblue">
                    <h3 className="flex gap-2 items-start justify-start text-lg sm:text-xl lg:text-2xl font-semibold font-Roboto text-white">
                        <span className="hidden sm:block">STAFF DASHBOARD</span>  <span className="leading-tight"> [Edit Course] </span>
                    </h3>
                </div>
                {/* create course form */}
                <form onSubmit={handleSubmit}>
                    <div className="w-full py-4 px-0 mt-2 max-h-[74vh]  md:max-h-[67vh] overflow-y-scroll">
                        {/* Heading and Subheading dection */}
                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2">
                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Course Title <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={name}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter Your Course Title..."
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Course Discription <span className="text-red-500">*</span>
                                </label>

                                <ReactQuill theme="snow"
                                    className="mt-2 appearance-none block w-full md:pr-3 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    name="description"
                                    type="text"
                                    value={description}
                                    onChange={setDescription}
                                />
                            </div>

                        </div>
                        {/* Title and Description section ends here */}

                        {/* About the course, Aims and Objective Goes here */}
                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2">
                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    About The Course <span className="text-red-500">*</span>
                                </label>

                                <ReactQuill theme="snow"
                                    className="mt-2 appearance-none block w-full md:pr-3 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    name="aboutthecourse"
                                    type="text"
                                    value={aboutthecourse}
                                    onChange={setAboutthecourse}
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Aims and Objectives <span className="text-red-500">*</span>
                                </label>

                                <ReactQuill theme="snow"
                                    className="mt-2 appearance-none block w-full md:pr-3 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    name="aimsandobjectives"
                                    type="text"
                                    //  onChange={(e) => setAimsandobjectives(e.target.value)}
                                    //   value={setAimsandobjectives}
                                    value={aimsandobjectives}
                                    onChange={setAimsandobjectives}
                                />
                            </div>
                        </div>
                        {/* About the course, Aims and Objective Ends here */}

                        {/* Duration and mode of class entry Section Goes Here */}
                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Program Duration <span className="text-red-500">*</span>
                                </label>

                                <input
                                    type="text"
                                    name="duration"
                                    id="duration"
                                    value={duration}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setDuration(e.target.value)}
                                    placeholder="Input Program Duratioin"
                                />
                            </div>

                            <div className="w-full pb-0">
                                <label className="pb-2 font-semibold">
                                    Mode of Class/Tutor <span className="text-red-500">*</span>
                                </label>

                                <input
                                    type="text"
                                    name="modeoftutor"
                                    value={modeoftutor}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setModeoftutor(e.target.value)}
                                    placeholder="Enter Tutur Mode..."
                                />
                            </div>

                        </div>
                        {/* Description and Duration Section Ends Here */}

                        {/* Start Day and time, End Day and time */}
                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    First Day<span className="text-red-500">*</span>
                                </label>
                                <div className="flex w-full">
                                    <select className="flex w-full py-1 mt-2 border-2 border-gray-200"
                                        value={days}
                                        onChange={(e) => setDays(e.target.value)} required>
                                        <option value="">Select Day</option>
                                        {weekdays &&
                                            weekdays?.map((i) => (
                                                <option value={i.title} key={i.title}>
                                                    {i.title}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>


                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Start Time <span className="text-red-500">*</span>
                                </label>

                                <input
                                    type="time"
                                    name="startTime"
                                    id="starttime"
                                    value={startTime}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setStartTime(e.target.value)}
                                    placeholder="Input Start Time"
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    End Time <span className="text-red-500">*</span>
                                </label>

                                <input
                                    type="time"
                                    name="endTime"
                                    id="endtime"
                                    value={endTime}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setEndTime(e.target.value)}
                                    placeholder="Input End Time"
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Second Day<span className="text-red-500">*</span>
                                </label>
                                <div className="flex w-full">
                                    <select className="flex w-full py-1 mt-2 border-2 border-gray-200"
                                        value={enddays}
                                        onChange={(e) => setEndDays(e.target.value)} required>
                                        <option value="">Select Day</option>
                                        {weekdays &&
                                            weekdays?.map((i) => (
                                                <option value={i.title} key={i.title}>
                                                    {i.title}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                        </div>
                        {/* Day, Duration, Date and Time Section Ends here */}

                        {/* Day, Duration, Date and Time Section Goes here */}
                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Start Date <span className="text-red-500">*</span>
                                </label>

                                <input
                                    type="date"
                                    name="startDate"
                                    id="start-date"
                                    // value={startDate ? startDate.toISOString().slice(0, 10) : ""}
                                    value={startDate.toISOString().slice(0, 10)}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setStartDate(new Date(e.target.value))}
                                    placeholder="Input Start Date"
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Time Zone <span className="text-red-500">*</span>
                                </label>

                                <div className="flex w-full">
                                    <select className="flex w-full py-1 mt-2 border-2 border-gray-200"
                                        value={timezone}
                                        onChange={(e) => setTimezone(e.target.value)} required>
                                        <option value="">Select Time Zone </option>
                                        {zones &&
                                            zones?.map((i) => (
                                                <option value={i.title} key={i.title}>
                                                    {i.title}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                        </div>
                        {/* Day, Duration, Date and Time Section Ends here */}

                        {/* Category Section Goes Here */}
                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
                            <div className="w-full pb-2 ">
                                <label className="w-full pb-2 font-semibold">
                                    Select Course Category <span className="text-red-500">*</span>
                                </label>
                                <div className="flex w-full">
                                    <select className="flex w-full py-1 border-2 border-gray-200" value={selectedCategory} onChange={handleCategoryChange} required>
                                        <option value="">Select Course Category</option>
                                        {categories.map((category) => (
                                            <option key={category._id} value={category._id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="w-full pb-2 ">
                                <label className="w-full pb-2 font-semibold">
                                    Select Course Sub-Category <span className="text-red-500">*</span>
                                </label>
                                <div className="flex w-full">
                                    <select className="flex w-full py-1 border-2 border-gray-200" value={selectedBrands} onChange={(e) => setSelectedBrands(e.target.value)} required>
                                        <option value="">Select Course</option>
                                        {filteredBrands.map((brand) => (
                                            <option key={brand._id} value={brand._id}>
                                                {brand.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Category Section Ends Here */}

                        {/* Original Price and Discount Price Section Goes Here */}
                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Original Price <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="number"
                                    name="originalPrice"
                                    value={originalPrice}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setOriginalPrice(e.target.value)}
                                    placeholder="Enter Original  Course Fee..."
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Discount Price <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="number"
                                    name="discountPrice"
                                    value={discountPrice}
                                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(e) => setDiscountPrice(e.target.value)}
                                    placeholder="Enter Discount Course Fee..."
                                />
                            </div>

                            <div className="w-full pb-2">
                                <label className="pb-2 font-semibold">
                                    Section Tags<span className="text-red-500">*</span>
                                </label>
                                <div className="flex w-full">
                                    <select className="flex w-full py-1 mt-2 border-2 border-gray-200"
                                        value={tags}
                                        onChange={(e) => setTags(e.target.value)} required>
                                        <option value=""></option>
                                        {categorytags &&
                                            categorytags?.map((i) => (
                                                <option value={i.section} key={i.section}>
                                                    {i.section}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Original Price and Discount Price Section Goes Here */}

                        <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">

                            {/* Upload Image first image */}
                            <div className="w-full pb-2 mt-2">
                                <label className="pb-2 font-semibold">
                                    Upload Images <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="file"
                                    name="upload1"
                                    id="upload1"
                                    className="hidden"
                                    multiple
                                    onChange={handleImageChange1}
                                />
                                <div className="flex flex-wrap items-center w-full">
                                    <label htmlFor="upload1">
                                        <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
                                    </label>
                                    {images1 &&
                                        images1.map((i) => (
                                            <img
                                                src={i}
                                                key={i}
                                                alt=""
                                                className="h-[120px] w-[120px] object-cover m-2"
                                            />
                                        ))}
                                </div>
                            </div>
                            {/* Upload first image ends here */}

                            {/* Upload Image Second Image for course Discription page image */}
                            {/* <div className="w-full pb-2 mt-2">
                                <label className="pb-2 font-semibold">
                                    Upload Images For Course Discription <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="file"
                                    name="upload2"
                                    id="upload2"
                                    className="hidden"
                                    multiple
                                    onChange={handleImageChange2}
                                />
                                <div className="flex flex-wrap items-center w-full">
                                    <label htmlFor="upload2">
                                        <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
                                    </label>
                                    {images2 &&
                                        images2.map((i) => (
                                            <img
                                                src={i}
                                                key={i}
                                                alt=""
                                                className="h-[120px] w-[120px] object-cover m-2"
                                            />
                                        ))}
                                </div>
                            </div> */}
                            {/* Upload Image Second Image for course Discription page image */}

                            {/* Upload Image third Image for course Discription page aims and objective image */}
                            {/* <div className="w-full pb-2 mt-2">
                                <label className="pb-2 font-semibold">
                                    Upload Images aims and Objective <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="file"
                                    name="upload3"
                                    id="upload3"
                                    className="hidden"
                                    multiple
                                    onChange={handleImageChange3}
                                />
                                <div className="flex flex-wrap items-center w-full">
                                    <label htmlFor="upload3">
                                        <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
                                    </label>
                                    {images3 &&
                                        images3.map((i) => (
                                            <img
                                                src={i}
                                                key={i}
                                                alt=""
                                                className="h-[120px] w-[120px] object-cover m-2"
                                            />
                                        ))}
                                </div>
                            </div> */}
                            {/*Upload Image third Image for course Discription page aims and objective image */}

                        </div>


                        <div className="flex w-full">
                            <div className="flex flex-wrap md:flex-nowrap w-full gap-2 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
                                <input
                                    type="submit"
                                    value="Update Course"
                                    className="mt-2 font-semibold cursor-pointer appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none bg-deepblue text-white focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                <Link
                                    to={"/dashboard-products"}
                                    className="flex font-semibold items-center justify-center mt-0 md:mt-2 cursor-pointer appearance-none w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none bg-red-900 text-white focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                >
                                    Cancle Edit
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditProduct;
