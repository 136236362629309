import React, { useEffect, useState } from "react";
import "./App.css";
// import './site.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  FAQPage,
  AboutusPage,
  ShippingPage,
  StoreLocationPage,
  ContactusPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  FeaturedProducts,
} from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopCreateCategory,
  ShopCreateBrand,
  ShopAllProducts,
  ShopPreviewPage,
  ShopAllOrders,
  ShopOrderDetails,
  ShopSettingsPage,
} from "./routes/ShopRoutes.js";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
} from "./routes/AdminRoutes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store.js";
import { loadUser } from "./redux/actions/user.js";
import ProtectedRoute from "./routes/ProtectedRoute.js";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute.js";
import { ShopHomePage } from "./ShopRoutes.js";
import SellerProtectedRoute from "./routes/SellerProtectedRoute.js";
import { getAllProducts } from "./redux/actions/product.js";

import ShopForgetPassword from "./components/Shop/ShopForgetPassword.jsx";
import ShopResetPassword from "./components/Shop/ShopResetPassword.jsx";
import Forgetpassword from "./components/Forgetpassword/Forgetpassword.jsx";
import OurTestimony from "./components/OurTestimony.jsx";
import Resetpassword from "./components/ResetPassword/Resetpassword.jsx";
// import AdminCreateProduct from "./pages/AdminCreateProduct.jsx";
// import AdminViewProduct from "./pages/AdminViewProduct.jsx";
import AdminCreateBanner from "./pages/AdminCreateBanner.jsx";
import AdminDashboardBanner from "./pages/AdminDashboardBanner.jsx";


// import EditProduct from "./components/Shop/EditProduct.jsx";
import ShopEditProduct from "./pages/Shop/ShopEditProduct.jsx";
import BrandPage from "./pages/BrandPage.jsx";
import { loadSeller } from "./redux/actions/sellers.js";
import SingleBlog from "./pages/SingleBlog.jsx";
import Blog from "./pages/Blog.jsx";
import Consult from './pages/Consult.jsx';
import ApplicationForm from './pages/ApplicationForm.jsx';
import AdminCreateTestimony from "./pages/AdminCreateTestimony.jsx";
import AdminDashboardTestimony from "./pages/AdminDashboardTestimony.jsx";
import { server } from "./server.js";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ShopOrderDetailsAdmin from "./pages/Shop/ShopOrderDetailsAdmin.jsx";

const App = () => {
  const [stripeApikey, setStripeApiKey] = useState("");

  async function getStripeApikey() {
    const { data } = await axios.get(`${server}/payment/stripeapikey`);
    setStripeApiKey(data.stripeApikey);
  }
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    getStripeApikey();
  }, []);

  return (
    <BrowserRouter>

      {stripeApikey && (
        <Elements stripe={loadStripe(stripeApikey)}>
          <Routes>
            <Route
              path="/payment"
              element={
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Elements>
      )}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/seller/activation/:activation_token"
          element={<SellerActivationPage />}
        />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/consult" element={<Consult />} />
        <Route path="/applicationform" element={<ApplicationForm />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<SingleBlog />} />

        <Route path="/brand/:id" element={<BrandPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/aboutus" element={<AboutusPage />} />
        <Route path="/location" element={<StoreLocationPage />} />
        <Route path="/privacy" element={<ShippingPage />} />
        <Route path="/contactus" element={<ContactusPage />} />
        <Route path="/checkout" element={<ProtectedRoute> <CheckoutPage /> </ProtectedRoute>} />
        <Route path="/order/success" element={<OrderSuccessPage />} />
        <Route path="/featuredproducts" element={<FeaturedProducts />} />
        <Route path="/testimonial" element={<OurTestimony />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/user/order/:id"
          element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          }
        />

        <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
        {/* shop Routes */}
        <Route path="/shop-create" element={<ShopCreatePage />} />
        <Route path="/shop-login" element={<ShopLoginPage />} />
        <Route path="/shop-forgot-password" element={<ShopForgetPassword />} />
        <Route path="/shop-password-reset/:token" element={<ShopResetPassword />} />
        <Route path="/user-forgot-password" element={<Forgetpassword />} />
        <Route path="/resets" element={<Resetpassword />} />
  
        <Route
          path="/shop/:id"
          element={
            <SellerProtectedRoute>
              <ShopHomePage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-product"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/edit-product/:id"
          element={
            <SellerProtectedRoute>
              <ShopEditProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-orders"
          element={
            <SellerProtectedRoute>
              <ShopAllOrders />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/order/:id"
          element={
            <SellerProtectedRoute>
              <ShopOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/order/admin/:id"
          element={
            <ProtectedRoute>
              <ShopOrderDetailsAdmin />
            </ProtectedRoute>
          }
        />
        {/* <Route
              path="/user/order/admin/:id"
              element={
                <ProtectedRoute>
                  <OrderDetailsAdmin />
                </ProtectedRoute>
              }
            /> */}
        <Route
          path="/dashboard-products"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />

        {/* section for create categories goes here */}
        <Route
          path="/dashboard-create-category"
          element={
            <SellerProtectedRoute>
              <ShopCreateCategory />
            </SellerProtectedRoute>
          }
        />
        {/* section for add categories ends here  */}

        {/* section for create categories goes here */}
        <Route
          path="/dashboard-create-brand"
          element={
            <SellerProtectedRoute>
              <ShopCreateBrand />
            </SellerProtectedRoute>
          }
        />
        {/* section for add categories ends here  */}

        {/* Admin Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-sellers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSellers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardOrders />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-products"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardProducts />
            </ProtectedAdminRoute>
          }
        />

        {/* <Route
          path="/admin-create-product"
          element={
            <ProtectedAdminRoute>
              <AdminCreateProduct />
            </ProtectedAdminRoute>
          }
        /> */}
        {/* <Route
          path="/admin-view-products"
          element={
            <ProtectedAdminRoute>
              <AdminViewProduct />
            </ProtectedAdminRoute>
          }
        /> */}
        <Route
          path="/admin-create-banner"
          element={
            <ProtectedAdminRoute>
              <AdminCreateBanner />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin-view-banners"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardBanner />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin-create-testimonys"
          element={
            <ProtectedAdminRoute>
              <AdminCreateTestimony />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path="/admin-view-testimonys"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardTestimony />
            </ProtectedAdminRoute>
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;