import React, { useState } from "react";
import { server } from "../../../server";
import { FiChevronDown, FiChevronUp, FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { BiMessageSquareDetail } from "react-icons/bi";
import logo2 from '../../../images/logo.png'
import axios from "axios";
import { toast } from "react-toastify";

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const stafflogoutHandler = () => {
    axios
      .get(`${server}/shop/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };


  return (
    <div className="w-full h-[90px] bg-deepblue border-b-4 border-green2 shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div className='flex md:ml-8'>
        <Link to="/" className=" w-[60px] md:w-[77px] px-3 py-2 bg-white rounded-lg md:rounded-3xl">
          <img
            src={logo2}
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-0 md:mr-4">
          <div className="flex items-center justify-center md:mr-6">
            {/* <Link to="/dashboard/cupouns" className="hidden 800px:block">
              <AiOutlineGift
                color="#fff"
                size={30}
                className="mx-3 cursor-pointer"
              />
            </Link>
            <Link to="/dashboard-events" className="hidden 800px:block">
              <MdOutlineLocalOffer
                color="#fff"
                size={30}
                className="mx-3 cursor-pointer"
              />
            </Link> */}
            <Link to="/dashboard-products" className="hidden 800px:block">
              <FiShoppingBag
                color="#fff"
                size={30}
                className="mx-3 cursor-pointer"
              />
            </Link>

            <Link to="/dashboard-orders" className="hidden 800px:block">
              <FiPackage color="#fff" size={30} className="mx-3 cursor-pointer" />
            </Link>
          </div>
          
          {dropdownVisible && (
            <div className="absolute top-[100%] right-3 md:right-6 bg-deepblue shadow-lg rounded-sm mt-0 list-drop font-semibold">
              <Link to="/" className="flex justify-center px-4 py-2 text-white hover:bg-darkblue">
                Back to websitess
              </Link>
              <Link to={`/shop/${seller._id}`} className="flex justify-center px-4 py-2 text-white hover:bg-darkblue">
                View Profile
              </Link>
              <p className="flex px-4 py-2 justify-center text-white hover:bg-red-600 cursor-pointer" onClick={stafflogoutHandler}>
                Logout
              </p>
            </div>
          )}

          {/* Image Link with Dropdown Icon */}
          <div className="relative flex items-center cursor-pointer list-drop2" onClick={handleDropdownToggle}>
            <img
              src={`${seller.avatar?.url}`}
              alt=""
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
            <p className="font-semibold ml-2 text-white">Profile Info</p>
            {dropdownVisible ? (
              <FiChevronUp className="ml-2 text-white text-2xl"/>
            ) : (
                <FiChevronDown className="ml-2 text-white text-2xl"/>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
