import { createContext, useContext, useRef, useState } from "react"
import { ChevronDown } from "react-feather";

const AccordianContext = createContext();

export default function Accordian({ children, value, onChange, ...props }) {
    const [selected, setSelected] = useState(value);

    // useEffect(() => {
    //     onChange?.(selected)
    // }, [selected])

    return (
        <ul {...props}>
            <AccordianContext.Provider value={{ selected, setSelected }}>
                {children}
            </AccordianContext.Provider>
        </ul>
    )
}


export function AccordianItem({ children, value, trigger, ...props }) {
    const { selected, setSelected } = useContext(AccordianContext);

    const open = selected === value;
    const ref = useRef(null)

    return (
        <li className="border-b bg-white text-black text-lg  rounded-lg " {...props}>
            <header
                role="button"
                // onClick={() => setSelected(open ? null : value)}
                onClick={() => setSelected(open ? " " : value)}
                className="flex justify-between items-center px-3 md:px-4 py-2 bg-deepblue text-white font-normal text-xs md:text-xl font-serif rounded-lg"
            >
                {trigger}
                <ChevronDown size={30} className={`transition-transform p-1 font-extrabold hover:bg-lightenblue ${open ? "rotate-180 bg-lightenblue rounded-full font-black" : " bg-black rounded-full font-white"}`} />
            </header>
            <div className="overflow-y-hidden transition-all"
                style={{ height: open ? ref.current?.offsetHeight || 0 : 0 }}
            >
                <div className="pt-2 p-4 text-justify text-sm md:text-lg lg:text-xl font-medium  rounded-lg" ref={ref}>
                    {children}
                </div>
            </div>
        </li>
    )
}
