import React, { useState, useEffect } from "react";

const Counter = ({ maxNumber }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => {
                if (prevCount < maxNumber) {
                    return prevCount + 1;
                } else {
                    clearInterval(interval);
                    return maxNumber;
                }
            });
        }, 20); // Adjust speed by changing this value

        return () => clearInterval(interval);
    }, [maxNumber]);

    return <span>{count}</span>;
};

const HappyClient = () => {
    return (
        <>
            <div className="py-10 mx-auto xl:max-w-[1320px] md:py-10 px-8 md:px-12 lg:py-16 lg:px-12 xl:px-12 rounded-xl">
                <div className="mx-auto max-w-xl text-center">
                    <p className="text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-bold leading-7 text-black text-center">
                        Our Happy Clients
                    </p>
                </div>
                <div className="flex flex-wrap mx-auto lg:mt-16 md:mt-10 mt-6 w-full overflow-hidden">
                    <div className="mx-auto items-start justify-between flex flex-wrap w-full gap-4 md:gap-0">
                        <div className="flex justify-center flex-col items-center w-32 h-32 md:w-40 md:h-44 lg:w-56 lg:h-56 bg-deepblue shadow rounded-2xl">
                            <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-white">
                                <Counter maxNumber={40} />+
                            </h2>
                            <p className="happclientParagraph">Happy Clients</p>
                        </div>
                        <div className="flex justify-center flex-col items-center w-32 h-32 md:w-40 md:h-44 lg:w-56 lg:h-56 bg-deepblue shadow rounded-2xl">
                            <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-white">
                                <Counter maxNumber={540} />+
                            </h2>
                            <p className="happclientParagraph">Completed Programs</p>
                        </div>
                        <div className="flex justify-center flex-col items-center w-32 h-32 md:w-40 md:h-44 lg:w-56 lg:h-56 bg-deepblue shadow rounded-2xl">
                            <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-white">
                                <Counter maxNumber={300} />
                            </h2>
                            <p className="happclientParagraph">Registered Members</p>
                        </div>
                        <div className="flex justify-center flex-col items-center w-32 h-32 md:w-40 md:h-44 lg:w-56 lg:h-56 bg-deepblue shadow rounded-2xl">
                            <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-white">
                                <Counter maxNumber={10} />+
                            </h2>
                            <p className="happclientParagraph">Awards Won</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HappyClient;
