import React from 'react'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AdminHeader from '../components/Layout/AdminHeader';
import CreateTestimony from '../components/Admin/CreateTestimony';
const AdminCreateTestimony = () => {
  return (
    <>
          <AdminHeader />
          <div className="flex justify-between w-full">
              <div className="w-[80px] 800px:w-[330px]">
                  <AdminSideBar active={13} />
              </div>
              <div className="w-full justify-center flex">
                  <CreateTestimony />
              </div>
          </div>
    </>
  )
}

export default AdminCreateTestimony
