import React from 'react'


const BannerCard = ({ data }) => {

    return (

        <div className="w-full h-[200px] md:h-[400px] lg:h-[500px] max-h-[500px]">
            <div className="flex md:items-center w-full h-[200px] md:h-[400px] lg:h-[500px] max-h-[500px] lg:mt-0">
                <div
                    className="flex w-full h-full bg-cover bg-center text-white bg-yellow-700"
                    style={{ backgroundImage: `url(${data.images[0]?.url})` }}
                >
                    {/* <div className="p-8 backdrop-filter backdrop-blur-xl backdrop-brightness-50 w-full"> */}

                    <div className=" p-3 pt-8 md:p-4 lg:p-8 bg-deepblue bg-opacity-30 w-full">

                        <h1 className="bannerFont mt-16 md:mt-20 lg:mt-28 md:text-6xl">
                            {data.title}
                        </h1>
                        <p className="subtilte ">
                            {data.subtitle}
                        </p>
                        <p className="titleText">
                            {data.littleText}
                        </p>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default BannerCard
