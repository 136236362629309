// redux/reducers/brand.js
import { createReducer } from "@reduxjs/toolkit";

// const initialState = {
//     brands: [],
//     isLoading: true,
// };

// export const brandReducer = createReducer(initialState, {
//     brandCreateRequest: (state) => {
//         state.isLoading = true;
//     },
//     brandCreateSuccess: (state, action) => {
//         state.isLoading = false;
//         state.brands.push(action.payload);
//         state.success = true;
//     },
//     brandCreateFail: (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//     },

//     getAllBrandsRequest: (state) => {
//         state.isLoading = true;
//     },
//     getAllBrandsSuccess: (state, action) => {
//         state.isLoading = false;
//         state.brands = action.payload;
//     },
//     getAllBrandsFail: (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//     },

//     brandUpdateRequest: (state) => {
//         state.isLoading = true;
//     },
//     brandUpdateSuccess: (state, action) => {
//         state.isLoading = false;
//         state.brands = state.brands.map(brand =>
//             brand._id === action.payload._id ? action.payload : brand
//         );
//         state.success = true;
//     },
//     brandUpdateFail: (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//     },

//     brandDeleteRequest: (state) => {
//         state.isLoading = true;
//     },
//     brandDeleteSuccess: (state, action) => {
//         state.isLoading = false;
//         state.brands = state.brands.filter(brand => brand._id !== action.payload);
//         state.success = true;
//     },
//     brandDeleteFail: (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//     },

//     clearErrors: (state) => {
//         state.error = null;
//     },
// });

// Initial state
const initialState = {
    brands: [],
    isLoading: true,
    success: false,
    error: null,
};

// Reducer using builder callback notation
export const brandReducer = createReducer(initialState, (builder) => {
    builder
        .addCase('brandCreateRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('brandCreateSuccess', (state, action) => {
            state.isLoading = false;
            state.brands.push(action.payload);
            state.success = true;
        })
        .addCase('brandCreateFail', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase('getAllBrandsRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('getAllBrandsSuccess', (state, action) => {
            state.isLoading = false;
            state.brands = action.payload;
        })
        .addCase('getAllBrandsFail', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase('brandUpdateRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('brandUpdateSuccess', (state, action) => {
            state.isLoading = false;
            state.brands = state.brands.map(brand =>
                brand._id === action.payload._id ? action.payload : brand
            );
            state.success = true;
        })
        .addCase('brandUpdateFail', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase('brandDeleteRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('brandDeleteSuccess', (state, action) => {
            state.isLoading = false;
            state.brands = state.brands.filter(brand => brand._id !== action.payload);
            state.success = true;
        })
        .addCase('brandDeleteFail', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase('clearErrors', (state) => {
            state.error = null;
        });
});
