// redux/reducers/category.js
import { createReducer } from "@reduxjs/toolkit";

// const initialState = {
//   categories: [],
//   isLoading: true,
//   error: null
// };

// export const categoryReducer = createReducer(initialState, {
//   categoryCreateRequest: (state) => {
//     state.isLoading = true;
//   },
//   categoryCreateSuccess: (state, action) => {
//     state.isLoading = false;
//     state.categories.push(action.payload);
//     state.success = true;
//   },
//   categoryCreateFail: (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
//   },

//   getAllCategoriesRequest: (state) => {
//     state.isLoading = true;
//   },
//   getAllCategoriesSuccess: (state, action) => {
//     state.isLoading = false;
//     state.categories = action.payload;
//   },
//   getAllCategoriesFail: (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
//   },

//   categoryUpdateRequest: (state) => {
//     state.isLoading = true;
//   },
//   categoryUpdateSuccess: (state, action) => {
//     state.isLoading = false;
//     state.categories = state.categories.map(category =>
//       category._id === action.payload._id ? action.payload : category
//     );
//     state.success = true;
//   },
//   categoryUpdateFail: (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
//   },

//   categoryDeleteRequest: (state) => {
//     state.isLoading = true;
//   },
//   categoryDeleteSuccess: (state, action) => {
//     state.isLoading = false;
//     state.categories = state.categories.filter(category => category._id !== action.payload);
//     state.success = true;
//   },
//   categoryDeleteFail: (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
//   },

//   clearErrors: (state) => {
//     state.error = null;
//   },
// });


// redux/reducers/category.js


// Define initial state
const initialState = {
  categories: [],
  isLoading: true,
  error: null,
  success: false
};

// Create the reducer using builder callback notation
export const categoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase('categoryCreateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('categoryCreateSuccess', (state, action) => {
      state.isLoading = false;
      state.categories.push(action.payload);
      state.success = true;
    })
    .addCase('categoryCreateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase('getAllCategoriesRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('getAllCategoriesSuccess', (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    })
    .addCase('getAllCategoriesFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase('categoryUpdateRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('categoryUpdateSuccess', (state, action) => {
      state.isLoading = false;
      state.categories = state.categories.map(category =>
        category._id === action.payload._id ? action.payload : category
      );
      state.success = true;
    })
    .addCase('categoryUpdateFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase('categoryDeleteRequest', (state) => {
      state.isLoading = true;
    })
    .addCase('categoryDeleteSuccess', (state, action) => {
      state.isLoading = false;
      state.categories = state.categories.filter(category => category._id !== action.payload);
      state.success = true;
    })
    .addCase('categoryDeleteFail', (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase('clearErrors', (state) => {
      state.error = null;
    });
});
