import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Container from '../components/Container';
import Meta from '../components/Meta';
import { IoHome } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPersonWalkingArrowLoopLeft } from "react-icons/fa6";
import CourseBanner from '../components/CourseBanner';
import banner from '../images/banner.png';

const ContactusPage = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_ldg8zxf', 'template_ju3ejnn', form.current, {
                publicKey: 'FqitxtQB-FI7H_6Fb',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    // Reset form fields
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <Header />
            <CourseBanner imageUrl={banner}>
                <h1 className=" font-bold">CONTACT US PAGE</h1>
            </CourseBanner>
            <Meta title={"Contact Us"} />
            <Container>
                <div className="py-10 bg-white md:pb-16 lg:pb-24 xl:pb-32 lg:px-6">
                    <div className="px-2 py-4 md:px-6 lg:py-12 bg-white  isolate lg:px-0">
                        <div className="max-w-2xl mx-auto lg:max-w-7xl">
                            <div className="grid grid-cols-1 rounded-xl bg-deepblue px-2 py-3 md:px-6 md:py-6 mt-0">
                                <h2 className="text-2xl md:text-4xl font-bold tracking-tight text-white">Let’s talk</h2>
                                <p className=" font-semibold mt-0 md:mt-2 text-base md:text-lg leading-8 text-white">
                                    Reach out fast to our response team
                                </p>
                            </div>
                            <div className="flex flex-col md:flex-row rounded-xl bg-white md:bg-deepblue p-0 md:px-6 md:py-10 gap-6 md:gap-8 mt-8">
                                <div className="grid grid-cols-1 gap-x-4 gap-y-3 md:flex md:flex-grow bg-lightenblue rounded-xl px-4 py-8 md:p-4 md:w-7/12">
                                    <form className='w-full flex flex-col md:gap-y-6' ref={form} onSubmit={sendEmail}>
                                        <div className="lg:flex w-full gap-y-4  gap-x-4">
                                            <div className='w-full'>
                                                <label className="block text-sm font-semibold leading-6 text-white">
                                                    Full Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="user_name"
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:flex w-full gap-y-4  gap-x-4">
                                            <div className="w-full mt-3 md:mt-0">
                                                <label className="block text-sm font-semibold leading-6 text-white">
                                                    Email
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="email"
                                                        name="user_email"
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full mt-3 md:mt-0">
                                                <label className="block text-sm font-semibold leading-6 text-white">
                                                    Phone Number
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="number"
                                                        name="user_phone"
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:flex w-full gap-y-4  gap-x-4">
                                            <div className="w-full mt-3 md:mt-0">
                                                <label className="block text-sm font-semibold leading-6 text-white">
                                                    Subject
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="user_subject"
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full sm:col-span-2 mt-3 md:mt-0">
                                                <label className="block text-sm font-semibold leading-6 text-white">
                                                    Message
                                                </label>
                                                <div className="mt-1">
                                                    <textarea
                                                        rows={4}
                                                        name="message"
                                                        defaultValue={''}
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 flex w-full">
                                            <button
                                                type="submit"
                                                value="Send"
                                                className="block w-full rounded-md bg-deepblue px-3.5 py-4 text-center text-sm font-semibold text-white shadow-sm hover:bg-deepblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                SUBMIT
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <div className="bg-lightenblue lg:mt-0 p-2 md:py-4 md:px-2 lg:flex-none md:h-52 md:max-h-72 rounded-xl lg:w-4/12">
                                    <figure className="mt-0">
                                        <blockquote className="text-lg font-semibold leading-8 ">
                                            <h2 className="text-xl md:text-2xl text-deepblue">
                                                Get In Touch With Us
                                            </h2>
                                            <ul className="mt-2 md:mt-4 gap-y-4">
                                                <li className="flex items-center md:mt-2">
                                                    <IoHome className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-base"> 421 East 58th Avenue,Vancouver BC, V5X 1W1 </p>
                                                </li>
                                                <li className="flex items-center md:mt-2">
                                                    <FaPhone className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-base"> +1 306 910 7944 </p>
                                                </li>
                                                <li className="flex items-center md:mt-2">
                                                    <MdEmail className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-base"> info@wumbisconcept.com </p>
                                                </li>
                                                <li className="flex items-center md:mt-2">
                                                    <FaPersonWalkingArrowLoopLeft className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-base"> Monday - Sunday (10am - 8pm) </p>
                                                </li>
                                            </ul>
                                        </blockquote>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default ContactusPage;
