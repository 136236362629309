import { Helmet } from "react-helmet";
import React from 'react'

const Meta = (props) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title> {props.title} </title>
            {/* <meta name="description" content="best online shop in nigeria, best online shop in africa, best online shop website , online shopping websitem, online shopping platforms, online shopping application" /> */}
        </Helmet>
    )
}

export default Meta;